// src/components/MedusaSection.js
import React from "react";
import "./MainSection.css";
// Ensure you have an image named medusa-image.jpg in the same directory

const MedusaSection = () => {
  return (
    <div className="medusa-container">
      <div className="medusa-content">
        <h1>Medusa Lounge</h1>
        <p>
          Experience the mystical and enchanting atmosphere of Medusa Lounge.
          Indulge in our premium hookah, delicious beverages, and captivating
          ambiance. Perfect for a night out with friends or a relaxing evening.
        </p>
      </div>
      <div className="medusa-image">
        <img src="./main-medusa.png" alt="Medusa Lounge" />
        <div className="smoke-container">
          <div className="smoke smoke-1"></div>
          <div className="smoke smoke-2"></div>
          <div className="smoke smoke-3"></div>
        </div>
      </div>
    </div>
  );
};
export default MedusaSection;
