import Navbar from "./Navbar";
import Footer from "./Footer";
import Contact from "./Contact";
import ImageGallery from "./ImageGallery";
import NoPage from "./NoPage";
import MedusaSection from "./MainSection";
import { BrowserRouter, Routes, Route } from "react-router-dom";

export default function App() {
  const images = [
    "https://i.ibb.co/MDwTG0f/medusa-carousel1.jpg",
    "https://i.ibb.co/kqsqbcK/medusa-carousel2.jpg",
    "https://i.ibb.co/h7wrv5F/medusa-carousel3.jpg",
    "https://i.ibb.co/4RDp1HF/medusa-carousel4.jpg",
    "https://i.ibb.co/MDwTG0f/medusa-carousel1.jpg",
    "https://i.ibb.co/kqsqbcK/medusa-carousel2.jpg",
    "https://i.ibb.co/h7wrv5F/medusa-carousel3.jpg",
    "https://i.ibb.co/4RDp1HF/medusa-carousel4.jpg",
    "https://i.ibb.co/MDwTG0f/medusa-carousel1.jpg",
    "https://i.ibb.co/kqsqbcK/medusa-carousel2.jpg",
    "https://i.ibb.co/h7wrv5F/medusa-carousel3.jpg",
    "https://i.ibb.co/4RDp1HF/medusa-carousel4.jpg",
  ];
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={<MedusaSection />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/gallery" element={<ImageGallery images={images} />} />
        <Route path="*" element={<NoPage />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}
