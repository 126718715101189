import React, { useState } from "react";
import "./ImageGallery.css";
const ImageGallery = ({ images }) => {
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (index) => {
    setSelectedImage(index);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <div className="image-gallery">
      {images.map((image, index) => (
        <img
          key={index}
          src={image}
          alt={`Medusa Lounge | Shisha Place | Best Shisha Cluj Napoca  ${
            index + 1
          }`}
          onClick={() => openModal(index)}
        />
      ))}
      {selectedImage !== null && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal">
            <img
              src={images[selectedImage]}
              alt={`Medusa Lounge | Shisha Place | Best Shisha Cluj Napoca  ${
                selectedImage + 1
              }`}
            />
            <button onClick={closeModal} className="btn">
              ✖
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageGallery;
