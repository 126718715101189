export default function NoPage() {
  return (
    <>
      <div
        style={{
          backgroundColor: "#000",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src="./logo-medusa.png"
          alt="medusa-logo"
          width={300}
          height={300}
        />
      </div>
      <div style={{ textAlign: "center", color: "#cca961" }}>
        <h1 style={{ fontSize: "64px" }}>404</h1>
        <h3>Pagina pe care tocmai ai cautat-o nu a fost gasita.</h3>
        <a href="https://medusalounge.ro">Intoarce-te inapoi.</a>
      </div>
    </>
  );
}
