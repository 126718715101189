import "./Contact.css";
import axios from "axios";
export default function Contact() {
  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);

    try {
      await axios.post("http://localhost:3001/send-email", {
        name: formData.get("name"),
        email: formData.get("email"),
        message: formData.get("message"),
      });
      alert("Mesajul a fost trimis cu succes!");
    } catch (error) {
      console.error("Error sending email:", error);
      alert("A intervenit o eroare...");
    }
  };

  return (
    <>
      <div className="contact">
        <div className="contact-container">
          <h1 style={{ textAlign: "center", color: "#cca961" }}>
            CONTACTEAZA-NE - Medusa Cluj
          </h1>
          <p style={{ padding: "5px", color: "#cca961", textAlign: "center" }}>
            Scrie-ne un mesaj si vom revenii cu un raspuns cat mai curand
            posibil.
          </p>
          <form className="contact-form" onSubmit={handleSubmit}>
            <label>Nume*</label>
            <input
              name="name"
              type="text"
              placeholder="Numele tau..."
              className="name-input"
              required
            />
            <label>Email*</label>
            <input
              name="email"
              type="email"
              placeholder="Adresa de email..."
              className="email-input"
              required
            />
            <label>Mesaj*</label>
            <input
              name="message"
              placeholder="Mesaj..."
              className="message-input"
              required
            />
            <button className="btn" style={{ marginTop: "20px" }} type="submit">
              Trimite mesajul
            </button>
          </form>
        </div>
      </div>
      <div className="contact-info">
        <div className="info-email">
          <img
            className="contact-img"
            src="./email.svg"
            alt="shisha-medusa-email-icon"
            width={50}
            height={50}
          />
          <p style={{ color: "#cca961" }}>office@medusalounge.ro</p>
        </div>
        <div className="info-phone">
          <img
            className="contact-img"
            src="./phone.svg"
            alt="shisha-pub-medusa-email-icon"
            width={50}
            height={50}
          />
          <p style={{ color: "#cca961" }}>+40748680155</p>
        </div>
      </div>
      <div className="google-maps" id="map-container">
        <iframe
          title="Shisha place - Medusa Lounge - Cluj Napoca"
          width="100%"
          height="450"
          style={{ border: "5px solid #cca961" }}
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2732.878756996537!2d23.591683476198774!3d46.76728624555861!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47490dea48962d83%3A0x93d1a2bc00bc437a!2sMedusa%20Lounge!5e0!3m2!1sro!2sro!4v1715256685888!5m2!1sro!2sro"
          allowfullscreen="true"
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </>
  );
}
