import { useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./index.css";

function Navbar() {
  const navRef = useRef();

  const toggleNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  const closeNavbar = () => {
    navRef.current.classList.remove("responsive_nav");
  };

  return (
    <header>
      <div class="image-container">
        <a href="https://medusalounge.ro">
          <img
            className="navbar-medusa"
            src="medusa-footer.png"
            alt="Medusa Lounge | Cluj Napoca | Shisha Cluj | Best shisha in Cluj | Cocktails | Hookah | Shisha"
            width={150}
            height={150}
          />
        </a>
      </div>
      <nav ref={navRef}>
        <Link to="/" onClick={closeNavbar}>
          Acasa
        </Link>
        <Link to="/gallery" onClick={closeNavbar}>
          Galerie
        </Link>
        <a
          href="https://meniu.medusalounge.ro/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Meniu
        </a>
        <Link to="/contact" onClick={closeNavbar}>
          Contact
        </Link>
        <button className="nav-btn nav-close-btn" onClick={toggleNavbar}>
          <FaTimes />
        </button>
      </nav>
      <button className="nav-btn" onClick={toggleNavbar}>
        <FaBars />
      </button>
    </header>
  );
}

export default Navbar;
