import React from "react";
import { useState, useEffect } from "react";
import "./Footer.css";

export default function Footer() {
  const currentYear = new Date().getFullYear();
  const [openingStatus, setOpeningStatus] = useState("");
  useEffect(() => {
    const currentDate = new Date();
    const currentHour = currentDate.getHours();
    const status = checkOpeningStatus(currentDate, currentHour);
    setOpeningStatus(status);
  }, []);

  function checkOpeningStatus(currentDate, currentHour) {
    let currentDay = currentDate.getDay();
    let isOpen = false;

    if (currentDay >= 1 && currentDay <= 4) {
      if (currentHour >= 14 || currentHour < 1) {
        isOpen = true;
      }
    } else if (currentDay === 0 || currentDay === 6 || currentDay === 5) {
      if (currentHour >= 14 || currentHour < 2) {
        isOpen = true;
      }
    }

    return isOpen ? "(Deschis acum)" : "(Inchis acum)";
  }

  return (
    <div className="footer-container">
      <div className="footer-logo">
        <img
          src="medusa-footer.png"
          alt="Medusa Lounge | Cluj Napoca | Shisha Cluj | Pub Cluj | Best Hookah"
          width={150}
          height={150}
        />
      </div>
      <div className="footer-links">
        <div className="footer-section">
          <h2 style={{ fontWeight: "600" }}>Program {openingStatus}</h2>
          <p>Luni-Joi: 14:00 - 01:00</p>
          <p>Vineri-Duminica: 14:00 - 02:00 </p>
        </div>
        <div className="footer-section">
          <h2>Unde ne gasiti?</h2>
          <a href="https://www.google.com/maps?sca_esv=053a0ad92a20350a&sxsrf=ACQVn0-fTsPMxsNrYHcx2X4L-IesIR6acg:1707312351327&uds=AMwkrPsAoZIcg-2Hf3F0Q4lzRaNOaHwvJhKWRPiN71pW2VgG1TuJZQKL7Z7t9UH1l8SO8xpwL03ENbJEtCDKm-mj7KyuJNWWeaZy2lK4KhMGrgQcqYnbqsJIZFUSeCRGodbpnl33KqVz&si=AKbGX_rcuucMgom2rba1cdwsvM2SVoIkP2btyqGMV4t0GuHIs4sGV_1tpuqR0YHG4Rzd9SdqNdxHfyvTRdxqevxYJD9d2qgKmgf7QZq-dU2wZxLfz84zJcc%3D&biw=1920&bih=862&dpr=1&um=1&ie=UTF-8&fb=1&gl=ro&sa=X&geocode=KYMtlkjqDUlHMXpDvAC8otGT&daddr=Str.+Avram+Iancu+29,+Cluj-Napoca+400347">
            Str. Avram Iancu nr.29, Cluj Napoca
          </a>
          <h2 style={{ marginTop: "5px" }}>Contact</h2>
          <a href="tel:0748680155">+40748680155</a>
        </div>
        <div className="footer-section">
          <h2>Follow us</h2>
          <div
            className="social-media-icons"
            style={{ display: "flex", gap: "10px", justifyContent: "center" }}
          >
            <a href="https://www.instagram.com/medusa.cluj/">
              <img
                src="instagram.svg"
                alt="Medusa Lounge | Cluj Napoca | Shisha Cluj | Pub Cluj | Best Hookah"
                width={37}
                height={37}
              />
            </a>
            <a href="https://www.facebook.com/profile.php?id=61556330185559">
              <img
                src="facebook.svg"
                alt="Medusa Lounge | Cluj Napoca | Shisha Cluj | Pub Cluj | Best Hookah"
                width={35}
                height={35}
              />
            </a>
            <a
              href="https://www.tiktok.com/@medusa_cluj"
              alt="medusa-lounge-tiktok"
            >
              <img
                src="tiktok.svg"
                alt="Medusa Lounge | Cluj Napoca | Shisha Cluj | Pub Cluj | Best Hookah"
                width={40}
                height={40}
              />
            </a>
          </div>
        </div>
      </div>
      <div className="footer-copyright" style={{ margin: "20px 0px" }}>
        <p style={{ fontSize: "11px" }}>
          Toate drepturile rezervate | Medusa Lounge &copy; {currentYear}.
        </p>
      </div>
    </div>
  );
}
